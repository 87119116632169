<!--
 * @Author: cyy
 * @Date: 2022-01-17 10:47:27
 * @LastEditTime: 2022-01-17 11:16:34
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\notOpened.vue
-->

<template>
  <div id="notOpened">
    <div class="title">
      免息券用于花呗分期免息，学员获得免息券后在支付相应的商品时，可选择花呗免息支付；目前支持的内容有课程、公开课、知识商品，资料、会员暂不支持，可以指定发放，也可以分享链接领取。
    </div>
    <div class="explain">请先开通支付宝花呗分期支付功能</div>
    <div class="empty-img">
      <img src="~@ass/img/1.4.5.5/img_qjty_zwnr.png" alt="" />
      <el-button
        @click="$router.push('/setting/paymentSetting')"
        type="primary"
        size="medium"
        style="width: 130px"
      >
        前往开通
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notOpened',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
#notOpened {
  .title {
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .explain {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    margin: 80px 0 50px;
    text-align: center;
  }
  .empty-img {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .el-button {
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}
</style>