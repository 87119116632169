<!--
 * @Author: cyy
 * @Date: 2022-01-13 16:58:47
 * @LastEditTime: 2022-01-19 15:40:46
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\grantDialog.vue
-->

<template>
  <div id="grantDialog">
    <!-- 发放 -->
    <el-dialog
      title="发放"
      class="grant-dialog"
      width="422px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="flex-center" style="font-size: 18px">
        发放
        <span v-if="grantDialogValue.value != 0" class="redcolor fs12 ml10">
          学员打开链接后，超过指定时间未领取就会失效
        </span>
      </div>
      <div class="grant-contain">
        <el-select
          :value="`领券时效：${grantDialogValue.label}`"
          @change="selectChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="`${item.label}`"
            :value="item"
            :style="
              item.value == grantDialogValue.value ? 'color:#0AA29B; ' : ''
            "
          ></el-option>
        </el-select>
        <div class="date-pick" v-if="grantDialogValue.value == 5">
          <el-date-picker
            @change="dataPickerChange"
            popper-class="installment-pick-time"
            :pickerOptions="pickerOptions"
            v-model="pickTime"
            type="datetime"
            placeholder="选择日期时间"
          ></el-date-picker>
        </div>
        <div
          class="contain-main"
          v-if="
            grantDialogValue.value != 5 ||
            (grantDialogValue.value == 5 && pickTime)
          "
        >
          {{ containMain }}
          <template v-if="containMain">
            <div id="ewm" ref="ewm"></div>
            <el-button type="text" @click="downEwm">
              下载二维码
              <!-- <a :href="containMainUrl">下载二维码</a> -->
            </el-button>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <span
          class="redcolor fs12"
          style="margin-right: 50px"
          v-if="selectCustom"
        >
          提示：请先选择日期时间并生成链接
        </span>
        <el-button
          type="primary"
          size="medium"
          class="info_right"
          @click="copy"
          :data-clipboard-text="containMain"
          :disabled="selectCustom"
          :style="selectCustom ? 'background:#E1E1E1;border:#E1E1E1' : ''"
        >
          复制文案+链接
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from '@/assets/js/qrcode'
import Clipboard from 'clipboard'
export default {
  name: 'grantDialog',
  data() {
    return {
      containMainUrl: '',
      containMain: '',
      pickTime: '',
      pickerOptions: {
        selectableRange: (() => {
          let data = new Date()
          let hour = data.getHours()
          let minute = data.getMinutes()
          let second = data.getSeconds()
          return [`${hour}:${minute + 5}:${second} - 23:59:59`]
        })(),
        disabledDate(time) {
          var date = new Date()
          date.setFullYear(date.getFullYear() + 2)
          date.setDate(date.getDate() - 1)
          return (
            time.getTime() < Date.now() - 8.64e7 ||
            time.getTime() > date.getTime()
          )
        },
      },
      grantDialogValue: {},
      dialogVisible: false, // 领券时效
      options2: [
        {
          value: 4,
          label: '15分钟',
        },

        {
          value: 2,
          label: '1分钟',
        },
        {
          value: 3,
          label: '3分钟',
        },
        {
          value: 1,
          label: '永久',
        },
        {
          value: 5,
          label: '自定义',
        },
      ],
    }
  },

  mounted() {
    this.grantDialogValue = this.options2[0]

    let text = ''
    switch (Number(this.grantDialogVal.specs)) {
      case 1:
        text = '3期'
        break
      case 2:
        text = '6期'
        break
      case 3:
        text = '12期'
        break
    }
    this.interesFree = text
    this.getInstallmentUrl()
  },

  computed: {
    /**
     * 选择自定义，但没选择时间的状态
     */
    selectCustom() {
      return this.grantDialogValue.value == 5 && !this.pickTime
    },
  },

  props: {
    grantDialogVal: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },

    // 生成二维码
    create_ewm(val) {
      const size = 100
      //生成前先清空原有的，不然会有多张二维码的情况
      document.getElementById('ewm').innerHTML = ''
      const node = this.$refs.ewm
      if (node) {
        this.qrcode = new QRCode(node, {
          text: val, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },

    // 获取发放链接
    async getInstallmentUrl() {
      try {
        const { data } = await this.$http({
          url: '/Installment/getInstallmentUrl',
          data: {
            type: this.grantDialogValue.value,
            installment_id: this.grantDialogVal.installment_id,
            etime: this.pickTime / 1000,
          },
        })
        this.containMain = ` 送你一张${this.interesFree}免息券，先到先得，火速打开链接领取 >>${data}`
        this.containMainUrl = data
        this.$nextTick(() => {
          this.create_ewm(data)
        })
      } catch (e) {
        console.log(e)
      }
    },

    // 时间选择器触发
    dataPickerChange() {
      this.getInstallmentUrl()
    },

    //复制
    copy() {
      var that = this
      var clipboard = new Clipboard('.info_right')
      clipboard.on('success', () => {
        that.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
        //这里你如果引入了elementui的提示就可以用，没有就注释即可
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        that.$root.prompt('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },

    //选择发放
    selectChange(val) {
      this.pickTime = ''
      this.containMain = ''
      this.grantDialogValue = val
      if (val != 5) {
        this.getInstallmentUrl()
      }
    },
  },
}
</script>

<style lang="less" >
.installment-pick-time {
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
::v-deep .grant-dialog {
  .el-dialog__body {
    padding: 10px 20px 10px;
  }
  .grant-contain {
    min-height: 240px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    .el-select {
      width: 100%;
      .el-input {
        .el-input__inner {
          background-color: #f4f4f4;
          border: 0;
          border-radius: 0;
        }
      }
    }
    .date-pick {
      margin: 20px 13px 0;
      .el-date-editor {
        width: 100%;
      }
    }

    .contain-main {
      padding: 20px 14px;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      #ewm {
        margin: 20px auto 0;
        width: 100px;
        height: 100px;
      }
      .el-button {
        margin-left: 50%;
        transform: translateX(-50%);
        a {
          color: #0aa29b;
        }
      }
    }
  }
}
</style>