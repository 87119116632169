<!--
 * @Author: cyy
 * @Date: 2022-01-11 11:54:43
 * @LastEditTime: 2022-01-19 17:40:45
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\installment\main.vue
-->
<template>
  <div>
    <div id="installment" v-if="$route.path == '/installment'">
      <!-- <not-opened></not-opened> -->
      <template v-if="$store.state.userInfo.is_alipay_flowers == 1">
        <div class="title-tip">
          <!-- 目前支持的内容有课程、公开课、知识商品，资料、会员暂不支持 -->
          免息券用于花呗分期免息，学员获得免息券后在支付相应的商品时，可选择花呗免息支付；目前暂不支持资料、会员使用；可以指定发放，也可以分享链接领取。
        </div>
        <div class="type-select">
          <div class="title">新建免息券</div>
          <div class="item t1 cp" @click="newInstallment(1)">
            <div class="interest-free">3期免息券</div>
            <div class="rate">费率 1.80%</div>
          </div>
          <div class="item t2 cp" @click="newInstallment(2)">
            <div class="interest-free">6期免息券</div>
            <div class="rate">费率 4.50%</div>
          </div>
          <div class="item t3 cp" @click="newInstallment(3)">
            <div class="interest-free">12期免息券</div>
            <div class="rate">费率 7.50%</div>
          </div>
        </div>
        <pagination2
          :option="post"
          url="/Installment/installmentList"
          ref="childDialogBox"
          class="installmentList"
          @complete="complete"
        >
          <template v-slot:default="{}">
            <div class="select-item">
              <el-select
                v-model="post.specs"
                style="width: 120px"
                size="medium"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                size="medium"
                v-model="post.name"
                style="width: 218px"
                class="ml20"
                placeholder="输入名称搜索"
              ></el-input>
            </div>
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableList"
              class="table-data"
            >
              <el-table-column label="名称&有效期" min-width="120px">
                <template slot-scope="{ row }">
                  <div class="name-time-specs">
                    <div class="name" :title="row.name">
                      {{ row.name }}
                    </div>
                    <div class="time">
                      领券后{{ row.receive_duration | formatTimeLength(2) }}失效
                    </div>
                    <div class="specs t1" v-if="row.specs == 1">3期</div>
                    <div class="specs t2 mt10 mb10" v-else-if="row.specs == 2">
                      6期
                    </div>
                    <div class="specs t3" v-else>12期</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="库存数量"
                min-width="150px"
                align="center"
              >
                <template slot-scope="{ row, $index }">
                  <div v-if="row.selectStatus">
                    <el-input-number
                      :min="0"
                      :max="99999"
                      :precision="0"
                      :step="1"
                      :key="$index"
                      size="small"
                      v-model="row.stock_num"
                    ></el-input-number>
                    <el-button
                      type="text"
                      class="ml10"
                      @click="saveNewNumber(row, 1, $index)"
                    >
                      保存
                    </el-button>
                    <el-button
                      style="color: #999999"
                      type="text"
                      class="ml10"
                      @click="saveNewNumber(row, 2)"
                    >
                      取消
                    </el-button>
                  </div>
                  <el-button
                    v-else
                    type="text"
                    @click="changeStockNum($index, true)"
                  >
                    {{ row.stock_num }}
                    <i class="el-icon-edit" style="color#0AA29B;"></i>
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="数据统计">
                <template slot-scope="{ row }">
                  <div class="static">
                    <p>已领取： {{ row.receive_num }}</p>
                    <p>已使用： {{ row.used_num }}</p>
                    <p>未使用： {{ row.un_used_num }}</p>
                    <p>已失效： {{ row.invalid_num }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="适用商品">
                <template slot-scope="{ row }">
                  <el-button
                    type="text"
                    @click="
                      $router.push(
                        `/installment/detailInstallment?installment_id=${row.installment_id}&&fromNewInstallment=2`
                      )
                    "
                  >
                    {{ row.num }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="120px">
                <template slot-scope="{ row }">
                  <el-button
                    type="text"
                    @click="
                      $router.push(
                        `/installment/detailInstallment?installment_id=${row.installment_id}`
                      )
                    "
                  >
                    详情
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="faFang(row)">发放</el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="edit(row)">编辑</el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="del(row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </template>
      <not-opened v-else></not-opened>

      <!-- 发放 -->
      <grantDialog
        v-if="grantDialogStatus"
        :dialogstatus.sync="grantDialogStatus"
        :grantDialogVal="grantDialogVal"
      ></grantDialog>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import notOpened from './notOpened.vue'
import grantDialog from './grantDialog'
import bg from '@/mixin/background'
export default {
  name: 'installment',
  mixins: [bg],

  components: {
    grantDialog,
    notOpened,
  },

  data() {
    return {
      grantDialogStatus: false,
      tableList: [],
      post: {
        specs: 0,
        name: '',
      },
      options: [
        {
          value: 0,
          label: '全部期数',
        },
        {
          value: 1,
          label: '3期',
        },
        {
          value: 2,
          label: '6期',
        },
        {
          value: 3,
          label: '12期',
        },
      ],
    }
  },

  mounted() {},

  methods: {
    //编辑
    edit(row) {
      this.$router.push({
        path: '/installment/newInstallment',
        query: { installment_id: row.installment_id },
      })
    },

    // 创建
    newInstallment(type) {
      this.$router.push({
        path: '/installment/newInstallment',
        query: { selectType: type },
      })
    },

    // 发放
    faFang(row) {
      this.grantDialogStatus = true
      this.grantDialogVal = row
      // this.containMain = ` 送你一张${this.interesFree}期免息券，先到先得，火速打开链接领取 >>
      //   https://lanhuapp.com/web/#/item/project/stage?tid=6702faf7-bc92-4c98-ba3e-d0630923e1ac&pid=d427ffce-9066-40f.com`
    },

    // 获取列表数据
    complete(val) {
      val.data.forEach((item) => {
        item.selectStatus = false
      })
      this.tableList = val.data
    },

    // 点击库存数量，修改状态
    changeStockNum(index, status) {
      this.tableList[index].selectStatus = status

      const arr = []

      this.tableList.forEach((item) => {
        arr.push(item)
      })

      this.tableList = arr
    },

    // 修改库存数量
    saveNewNumber(row, type, index) {
      if (type == 1) {
        this.$http({
          url: '/Installment/setStock',
          data: {
            num: row.stock_num,
            installment_id: row.installment_id,
          },
          callback: () => {
            this.changeStockNum(index, false)
          },
        })
      } else {
        this.$refs.childDialogBox.reset()
      }
    },

    // 删除
    del(row) {
      this.$confirm(
        '删除后，免息券的全部数据将被清理，不可恢复，是否确定删除？',
        '删除免息券',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/Installment/delInstallment',
            data: {
              installment_id: row.installment_id,
            },
            callback: () => {
              this.$root.prompt({
                type: 'success',
                msg: '操作成功',
              })
              this.$refs.childDialogBox.reset()
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" >
.installment-pick-time {
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
#installment {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  .t1 {
    background: #647aff;
  }
  .t2 {
    background: #fea467;
  }
  .t3 {
    background: #2dca72;
  }
  .title-tip {
    font-size: 13px;
    color: #333333;
    line-height: 20px;
  }
  .type-select {
    display: flex;
    align-items: center;
    margin: 30px 0;
    .title {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
    }
    .item {
      margin-left: 30px;
      width: 177px;
      height: 70px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.25s;
      &:hover {
        transform: translateY(-5px);
        &.t1 {
          background: #4f62d1;
        }
        &.t2 {
          background: #d18855;
        }
        &.t3 {
          background: #26a55e;
        }
      }
      .interest-free {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
      }
      .rate {
        font-size: 14px;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
  .installmentList {
    .select-item {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .table-data {
      .name-time-specs {
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 15px;
          font-weight: bold;
          color: #333333;
        }
        .time {
          font-size: 13px;
          color: #666666;
        }
        .specs {
          width: 30px;
          height: 19px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #ffffff;
          margin-top: 5px;
        }
      }
      .static {
        font-size: 12px;
        color: #333333;
        line-height: 19px;
      }
      ::v-deep .el-divider {
        background-color: #0aa29b;
      }
    }
  }
}
</style>
